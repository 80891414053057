var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { TextField, } from '@mui/material';
import { forwardRef, useState, } from 'react';
import InputLabel from '@shared/ui/inputs/InputLabel';
const INPUT_SIZES = {
    medium: {
        labelSize: '16px',
        labelMarginBottom: '12px',
        lineHeight: '24px',
        valueSize: '16px',
        inputPadding: '12px 16px',
        inputHeight: '24px',
    },
    small: {
        labelSize: '14px',
        labelMarginBottom: '10px',
        lineHeight: '20px',
        valueSize: '14px',
        inputPadding: '10px 12px',
        inputHeight: '20px',
    },
};
const getDisabledProps = (theme, isDisabled) => (isDisabled ? ({
    '& .MuiInputBase-root': {
        backgroundColor: `${theme.customColors.input.backgroundDisabled}`,
    },
    '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: 'inherit',
    },
}) : {});
const defineFocusedColor = (theme, status) => {
    if (status === 'success') {
        return {
            boxShadow: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
            borderColor: theme.palette.success.main,
        };
    }
    if (status === 'error') {
        return {
            boxShadow: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
            border: '',
        };
    }
    return {
        boxShadow: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
    };
};
const InputStyled = styled(TextField, {
    shouldForwardProp: (propName) => propName !== 'filled' &&
        propName !== 'fillColor' &&
        propName !== 'hasCounter' &&
        propName !== 'notificationStatus' &&
        propName !== 'hasLabel',
})((props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return (Object.assign({ '& .MuiInputBase-root': {
            width: 'inherit',
            backgroundColor: (props.filled ?
                (props.fillColor || props.theme.customColors.surface.surface) :
                'inherit'),
        }, '& .MuiInputBase-root.Mui-focused fieldset': Object.assign({}, defineFocusedColor(props.theme, props.notificationStatus)), '& .MuiInputBase-input': {
            fontSize: (_a = INPUT_SIZES[props.size]) === null || _a === void 0 ? void 0 : _a.valueSize,
            padding: (_b = INPUT_SIZES[props.size]) === null || _b === void 0 ? void 0 : _b.inputPadding,
            lineHeight: (_c = INPUT_SIZES[props.size]) === null || _c === void 0 ? void 0 : _c.lineHeight,
            height: (_d = INPUT_SIZES[props.size]) === null || _d === void 0 ? void 0 : _d.inputHeight,
            color: props.theme.palette.text.primary,
        }, '&.MuiTextField-root.MuiFormControl-root': {
            width: props.fullWidth ? '100%' : 'inherit',
        }, '& label.MuiInputLabel-root': {
            position: 'relative',
            top: 0,
            left: 0,
            transform: 'none',
            marginBottom: props.hasLabel ?
                (_e = INPUT_SIZES[props.size]) === null || _e === void 0 ? void 0 : _e.labelMarginBottom :
                0,
            fontWeight: 500,
            fontSize: (_f = INPUT_SIZES[props.size]) === null || _f === void 0 ? void 0 : _f.labelSize,
            lineHeight: (_g = INPUT_SIZES[props.size]) === null || _g === void 0 ? void 0 : _g.lineHeight,
            color: props.theme.palette.text.primary,
            display: 'flex',
            justifyContent: 'space-between',
        }, '& label.MuiInputLabel-root.Mui-disabled': {
            position: 'relative',
            top: 0,
            left: 0,
            transform: 'none',
            marginBottom: props.hasLabel ?
                (_h = INPUT_SIZES[props.size]) === null || _h === void 0 ? void 0 : _h.labelMarginBottom :
                0,
            fontWeight: 500,
            fontSize: (_j = INPUT_SIZES[props.size]) === null || _j === void 0 ? void 0 : _j.labelSize,
            color: props.theme.palette.text.primary,
        }, '& .MuiInputLabel-asterisk': {
            color: props.required ? props.theme.palette.text.primary : props.theme.palette.error.main,
        }, legend: {
            visibility: 'hidden',
            width: 0,
        }, '& .MuiFormHelperText-root': {
            marginLeft: 0,
            marginTop: props.theme.spacing_sizes.xs,
            fontSize: '14px',
            lineHeight: '18px',
        } }, getDisabledProps(props.theme, props.disabled)));
});
const InputComponent = (_a, ref) => {
    var { filled = false, fillColor = undefined, notification = undefined, notificationStatus = undefined, size = 'small', startAdornment = undefined, endAdornment = undefined, maxCount = undefined, value = undefined, label = undefined, onBlur = undefined, required = undefined, onFocus = undefined } = _a, props = __rest(_a, ["filled", "fillColor", "notification", "notificationStatus", "size", "startAdornment", "endAdornment", "maxCount", "value", "label", "onBlur", "required", "onFocus"]);
    const [focused, setIsFocused] = useState(false);
    const handleBlur = (ev) => {
        setIsFocused(false);
        if (onBlur) {
            onBlur(ev);
        }
    };
    const handleFocus = (ev) => {
        setIsFocused(true);
        if (onFocus) {
            onFocus(ev);
        }
    };
    return (_jsx(InputStyled, Object.assign({}, props, { label: (_jsx(InputLabel, { count: value === null || value === void 0 ? void 0 : value.length, focused: focused, label: label, maxCount: maxCount, size: size, required: required })), hasLabel: typeof label !== 'undefined', value: value, size: size, variant: 'outlined', notificationStatus: notificationStatus, filled: !!filled, fillColor: fillColor, helperText: notification, error: notificationStatus === 'error', InputProps: { startAdornment, endAdornment }, onBlur: handleBlur, onFocus: handleFocus, ref: ref })));
};
const Input = forwardRef(InputComponent);
export default Input;
